import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

// Data
import settingsDataTable from "layouts/settings/data/settingsDataTable";
import { getAllSettings } from "service/settingsService";
import { updateSettings } from "service/settingsService";

function SettingsTable() {
	const [settings, setSettings] = React.useState([]);

	const { columns, rows } = settingsDataTable(settings);

	useEffect(() => {
		async function getSettings() {
			var mySettings = [];
			const result = await getAllSettings();
			if (result !== undefined) {
				result.map((item) => {
					const setting = {
						id: item.id,
						label: item.label,
						value: item.value,
					};
					mySettings.push(setting);
				});
				setSettings(mySettings);
			}
		}

		getSettings();
	}, []);

	const addSettings = () => {
		async function updateMySettings() {
			await updateSettings(settings);
		}
		updateMySettings();
	};

	const settingsChange = () => {
		setSettings(settings);
	};

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white" textAlign="middle">
									Settings
								</MDTypography>
								<MDButton
									variant="contained"
									iconOnly={true}
									size="medium"
									color="primary"
									onClick={addSettings}>
									<Icon fontSize="medium">save</Icon>
								</MDButton>
							</MDBox>
							<MDBox sx={{ width: "100%" }} pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default SettingsTable;
