import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getAllNews } from "service/blogService";
import BlogForm from "./blogForm";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import BlogDataTable from "./data/BlogDataTable";

function BlogSection() {
	const [open, setOpen] = useState(false);
	const [blogs, setBlogs] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);

	useEffect(() => {
		async function getNews() {
			const news = await getAllNews();
			if (news) {
				setBlogs(news);
			}
		}

		getNews();
	}, []);

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const blogValues = {
		title: "",
		details: "",
		tag: "",
	};

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white">
									News
								</MDTypography>
								<MDButton
									iconOnly={true}
									variant="contained"
									size="medium"
									color="primary"
									onClick={handleClickOpen}>
									<Icon fontSize="small">add</Icon>
								</MDButton>
							</MDBox>
							<MDBox pt={3}>
								<BlogDataTable blogs={blogs}></BlogDataTable>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<BlogForm
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
						setBlogs={setBlogs}
						blogs={blogs}
						setOpen={setOpen}
						initialValues={blogValues}
					/>
				}></ResponsiveDialog>
		</DashboardLayout>
	);
}

export default BlogSection;
