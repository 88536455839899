import axios from "axios";
import customAxios from "utils/axios";

export async function getAllNews() {
	try {
		const response = await customAxios.get("/api/news");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function addNews(data) {
	const formData = new FormData();
	formData.append("title", data.title);
	formData.append("details", data.details);
	formData.append("tags", data.tags);
	formData.append("image", data.image);

	const response = await customAxios.post(`/api/news`, formData, {
		headers: { "content-type": "application/x-www-form-urlencoded" },
	});
	return response.data;
}

export async function editNews(data) {
	const formData = new FormData();
	formData.append("id", data.id);
	formData.append("title", data.title);
	formData.append("details", data.details);
	formData.append("tags", data.tags);
	formData.append("image", data.image);

	const response = await customAxios.put(`/api/news`, formData, {
		headers: { "content-type": "application/x-www-form-urlencoded" },
	});
	return response.data;
}

export async function deleteNews(id) {
	const response = await axios.delete(`/api/delete/${id}`);
	return response.data;
}
