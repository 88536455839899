import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import { getAllOrders } from "service/orderService";
import OrdersDataTable from "./data/OrdersDataTable";

function OrdersTables() {
	const [orders, setOrders] = useState([]);

	useEffect(() => {
		async function getOrders() {
			const result = await getAllOrders();
			if (result.orders) {
				setOrders(result.orders);
			}
		}
		getOrders();
	}, []);

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white" textAlign="middle">
									Orders
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								<OrdersDataTable orders={orders}></OrdersDataTable>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default OrdersTables;
