import React, { useContext, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import { getAllProducts } from "service/productService";
import ProductForm from "./productForm";
import ProductsDataTable from "./data/ProductsDataTable";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import ChosenIngredientsForm from "./productForm/productIngredientsChoose";

function Products() {
	const showNotification = useContext(SnackbarContext);
	const [products, setProducts] = useState([]);
	const [open, setOpen] = useState(false);
	const [openIngredients, setOpenIngredients] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);

	const [selectedIngredientsData, setSelectedIngredientsData] = useState([]);

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	const productInitialValues = {
		name: "",
		image: "",
		details: "",
		quantity: "",
		price: "",
		salePrice: "",
		ingredients: "",
	};

	useEffect(() => {
		async function getProducts() {
			try {
				const result = await getAllProducts();
				if (result && result.products) {
					setProducts(result.products);
				}
			} catch (err) {
				showNotification("Fetch products failed", "error");
			}
		}
		getProducts();
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const sendOpenState = (open) => {
		setOpen(false);
	};

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white" textAlign="middle">
									Products
								</MDTypography>
								<MDButton
									iconOnly={true}
									variant="contained"
									size="medium"
									color="primary"
									onClick={handleClickOpen}>
									<Icon fontSize="small">add</Icon>
								</MDButton>
							</MDBox>
							<MDBox pt={3}>
								<ProductsDataTable products={products}></ProductsDataTable>
								{/* <DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/> */}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<ProductForm
						setProducts={setProducts}
						products={products}
						setOpen={sendOpenState}
						selectedIngredientsData={selectedIngredientsData}
						setSelectedIngredientsData={setSelectedIngredientsData}
						initialValues={productInitialValues}
						openIngredients={openIngredients}
						setOpenIngredients={setOpenIngredients}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				minWidth={"70%"}
				open={openIngredients}
				setOpen={setOpenIngredients}
				component={
					<ChosenIngredientsForm
						selectedIngredientsData={selectedIngredientsData}
						setOpen={setOpenIngredients}
						sendData={setSelectedIngredientsData}
					/>
				}></ResponsiveDialog>
		</DashboardLayout>
	);
}

export default Products;
