import React, { useState, useEffect, useContext, useRef } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getAllCategories } from "service/categoryService";
import { addProduct, updateProduct } from "service/productService";
import { FormControl, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import ImageUpload from "components/MDImageUpload";
import DataTable from "examples/Tables/DataTable";
import ProductIngredientsDataTable from "./productIngredientsChoose/data/productIngredientsTableData";
import SimpleReactValidator from "simple-react-validator";

const ProductForm = (props) => {
	const showNotification = useContext(SnackbarContext);
	const validator = useRef(new SimpleReactValidator());
	const [unit, setUnit] = useState({ value: "", error: true });
	const [name, setName] = useState({ value: "", error: false });
	const [details, setDescription] = useState({ value: "", error: false });
	const [quantity, setQuantity] = useState({ value: 0, error: false });
	const [kcal, setKcal] = useState({ value: 0, error: false });
	const [allergens, setAllergens] = useState({ value: "", error: false });
	const [status, setStatus] = useState({ value: false, error: false });
	const [price, setPrice] = useState({ value: 0, error: false });
	const [salePrice, setSalePrice] = useState({ value: 0, error: false });
	const [image, setImage] = useState(
		props.currentProduct && props.currentProduct.id
			? `/api/image/products/o/webp/${props.currentProduct.id}.webp`
			: ""
	);
	const [categoryId, setCategoryId] = useState("");
	const [favorite, setFavorite] = useState(false);
	const [recommended, setRecommended] = useState(false);
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState("");
	const { columns, rows } = ProductIngredientsDataTable(
		props.selectedIngredientsData ? props.selectedIngredientsData : []
	);

	useEffect(() => {
		if (props.currentProduct) {
			setName({ value: props.currentProduct.name, error: false });
			setDescription({ value: props.currentProduct.details, error: false });
			setQuantity({ value: props.currentProduct.qty, error: false });
			setKcal({ value: props.currentProduct.kcal, error: false });
			setUnit({ value: props.currentProduct.unit, error: false });
			setAllergens({ value: props.currentProduct.allergens, error: false });
			setStatus({ value: props.currentProduct.status, error: false });
			setPrice({ value: props.currentProduct.price, error: false });
			setSalePrice({ value: props.currentProduct.sale_price, error: false });
			setFavorite(props.currentProduct.favorite);
			setRecommended(props.currentProduct.recommended);
			if (!props.selectedIngredientsData && props.currentProduct.ingredients) {
				props.setSelectedIngredientsData(props.currentProduct.ingredients);
			}
			if (props.currentProduct.category && props.currentProduct.category.id) {
				setCategoryId(props.currentProduct.category.id);
			}
		}
	}, [props, props.currentProduct]);

	useEffect(() => {
		const currentValidator = validator.current;
		return () => currentValidator.hideMessages(); // cleanup function
	}, []);

	const handleFavoriteCheckboxChange = (e) => {
		setFavorite(e.target.checked);
	};

	const handleStatusCheckboxChange = (e) => {
		setStatus({ value: e.target.checked, error: false });
	};

	const handleRecommendedCheckboxChange = (e) => {
		setRecommended(e.target.checked);
	};

	const handleCategoryChange = (event) => {
		categories.forEach((category) => {
			if (category.name === event.target.value) {
				setCategoryId(category.id);
				setCategory(event.target.value);
			}
		});
	};

	const handleClickOpen = () => {
		props.setOpenIngredients(true);
	};

	useEffect(() => {
		async function getCategories() {
			try {
				const result = await getAllCategories();

				if (result) {
					setCategories(result);
					if (props.currentProduct && props.currentProduct.category) {
						if (props.currentProduct.category.name) {
							setCategory(props.currentProduct.category.name);
						}
					}
				} else {
					setCategories([]);
				}
			} catch (err) {
				throw err;
			}
		}

		getCategories();
	}, [props.currentProduct]);

	const handleProduct = async () => {
		let ingredients = props.selectedIngredientsData;
		let result = null;
		const product = {
			name: name.value,
			details: details.value,
			qty: parseFloat(quantity.value),
			price: parseFloat(price.value),
			sale_price: parseFloat(salePrice.value),
			unit: unit.value,
			kcal: kcal.value,
			allergens: allergens.value,
			status: status.value,
			image: image,
			recommended: recommended,
			favorite: favorite,
			categoryId: categoryId,
			ingredients: ingredients,
			category: category,
		};
		if (!validator.current.allValid()) {
			showNotification("You need to complete all required fields");
			return;
		}
		if (props.currentProduct) {
			product.id = props.currentProduct.id;
			try {
				result = await updateProduct(product);
				showNotification("Success edit product", "success");
			} catch (err) {
				showNotification("Error edit product", "error");
			}
		} else {
			try {
				result = await addProduct(product);
				showNotification("Success create product", "success");
			} catch (err) {
				showNotification("Error create product", "error");
			}
		}

		if (result) {
			const copyProducts = JSON.parse(JSON.stringify(props.products));
			const currentProductIndex = copyProducts.findIndex((p) => p.id === result.id);
			if (currentProductIndex !== -1) {
				copyProducts[currentProductIndex] = result;
			} else {
				copyProducts.push(result);
			}
			props.setProducts(copyProducts);
			props.setOpen(false);
			props.setSelectedIngredientsData([]);
		}
	};

	const validateNameField = (event) => {
		setName({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("name");
	};

	const validateDetailsField = (event) => {
		setDescription({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("details");
	};

	const validateAllergens = (event) => {
		setAllergens({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("allergens");
	};

	const validatePrice = (event) => {
		setPrice({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("price");
	};

	const validateSalePrice = (event) => {
		setSalePrice({ value: event.target.value, error: false });
	};

	const validateQuantity = (event) => {
		setQuantity({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("quantity");
	};

	const validateUnit = (event) => {
		setUnit({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("unit");
	};

	const validateKcal = (event) => {
		setKcal({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("kcal");
	};

	return (
		<MDBox sx={{ width: "100%" }}>
			<MDTypography variant="caption" px={3} fontWeight="bold" style={{ fontSize: "25px" }}>
				{props.currentProduct && props.currentProduct.id ? "Edit product" : "Create product"}
			</MDTypography>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							alignItems: "center",
							gap: "8px",
						}}>
						<MDBox mb={2} sx={{ width: "65%" }}>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Name"
									value={name.value}
									error={name.error}
									onChange={(event) => validateNameField(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("name", name.value, "required")}
								</MDTypography>
							</MDBox>
							<FormControl sx={{ width: "100%", height: "2.1375em" }}>
								<InputLabel id="select-outlined-label">Select category</InputLabel>
								<Select
									labelId="select-outlined-label"
									id="select-outlined"
									sx={{
										width: "100%",
										height: "100%",
										"& .MuiSelect-outlined": {
											height: "100% !important",
										},
									}}
									value={category}
									onChange={handleCategoryChange}
									label="Select category">
									{categories.map((category, index) => (
										<MenuItem key={index} value={category.name}>
											{category.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</MDBox>
						<MDBox
							mb={2}
							sx={{ width: "35%", justifyContent: "center", display: "flex", alignItems: "center" }}>
							<ImageUpload defaultImage={image} onImageUpload={(image) => setImage(image)}></ImageUpload>
						</MDBox>
					</MDBox>
					<MDBox mb={2} pl={1}>
						<FormControlLabel
							sx={{ width: "fit-content" }}
							control={<Checkbox checked={status.value} onChange={handleStatusCheckboxChange} />}
							label="Available"
						/>
					</MDBox>
					<MDBox mb={2} sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
						<MDInput
							type="number"
							label="Price"
							error={price.error}
							value={price.value ? price.value : 0}
							onChange={(event) => validatePrice(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("price", price.value, "required")}
						</MDTypography>
						<MDInput
							type="number"
							label="Sale price"
							value={salePrice.value ? salePrice.value : 0}
							onChange={(event) => validateSalePrice(event)}
							fullWidth
						/>
					</MDBox>

					<MDBox mb={2} sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
						<MDInput
							type="number"
							label="Quantity"
							error={quantity.error}
							value={quantity.value}
							onChange={(event) => validateQuantity(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("quantity", quantity.value, "required")}
						</MDTypography>
						<MDInput
							type="text"
							label="Unit"
							value={unit.value}
							error={unit.error}
							onChange={(event) => validateUnit(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("unit", unit.value, "required")}
						</MDTypography>
						<MDInput
							type="number"
							label="Kcal"
							value={kcal.value}
							error={kcal.error}
							onChange={(event) => validateKcal(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("kcal", kcal.value, "required")}
						</MDTypography>
					</MDBox>

					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Description"
							value={details.value}
							error={details.error}
							multiline
							rows={5}
							onChange={(event) => validateDetailsField(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("details", details.value, "required")}
						</MDTypography>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Allergens"
							error={allergens.error}
							value={allergens.value}
							multiline
							rows={3}
							onChange={(event) => validateAllergens(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("allergens", allergens.value, "required")}
						</MDTypography>
					</MDBox>
					<MDBox pl={1} mb={2} sx={{ width: "100%", display: "flex", justifyContent: "row" }}>
						<FormControlLabel
							control={<Checkbox checked={favorite} onChange={handleFavoriteCheckboxChange} />}
							label="Favorite"
						/>
						<FormControlLabel
							control={<Checkbox checked={recommended} onChange={handleRecommendedCheckboxChange} />}
							label="Recommended"
						/>
					</MDBox>
					<MDBox mt={2} style={{ marginBottom: "10%", width: "100%" }}>
						{rows && rows.length > 0 && (
							<MDBox pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						)}
						<MDBox
							pt={2}
							sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
							<MDButton
								type="button"
								sx={{ width: "200px" }}
								variant="contained"
								color="secondary"
								onClick={handleClickOpen}>
								{props.currentProduct && props.currentProduct.id
									? "Edit ingredients"
									: "Add ingredients"}
							</MDButton>
						</MDBox>
					</MDBox>
					<MDBox mt={2} sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
						<MDButton type="button" variant="contained" color="primary" onClick={handleProduct}>
							<Icon fontSize="medium">save</Icon>
							SAVE
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default ProductForm;
