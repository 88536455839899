import customAxios from "utils/axios";

export async function getAllSettings() {
	try {
		const response = await customAxios.get("/api/settings");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function getPrivacyPolicy() {
	try {
		const response = await customAxios.get("/api/privacyPolicy");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function getAllergens() {
	try {
		const response = await customAxios.get("/api/allergens");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function getTermsAndConditions() {
	try {
		const response = await customAxios.get("/api/termsAndConditions");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function updateSettings(data) {
	const response = await customAxios.put(`/api/settings`, data);
	return response.data;
}

export async function addPrivacyPolicy(data) {
	const response = await customAxios.post(`/api/privacyPolicy`, {
		value: data.value,
		label: data.label,
		type: data.type,
	});
	return response.data;
}

export async function addTermsAndConditions(data) {
	const response = await customAxios.post(`/api/termsAndConditions`, {
		value: data.value,
		label: data.label,
		type: data.type,
	});
	return response.data;
}

export async function addAllergens(data) {
	const response = await customAxios.post(`/api/allergens`, {
		value: data.value,
		label: data.label,
		type: data.type,
	});
	return response.data;
}
