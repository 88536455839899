import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ContentState, EditorState, convertToRaw } from "draft-js";

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";

function ContentEditor({ getContent, saveContent, label }) {
	const showNotification = useContext(SnackbarContext);
	const [content, setContent] = useState({});
	const [value, setValue] = useState(EditorState.createEmpty());
	const [initialValue, setInitialValue] = useState(EditorState.createEmpty());

	useEffect(() => {
		async function fetchContent() {
			const result = await getContent();
			if (result) {
				setContent(result);
				setEditorDefaultValue(result.value);
			}
		}
		fetchContent();
	}, [getContent]);

	const onEditorChange = (event) => {
		const content = event.getCurrentContent();
		setValue(event);
		setContent((prevState) => ({ ...prevState, value: content }));
	};

	const setEditorDefaultValue = (html) => {
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
			const editorState = EditorState.createWithContent(contentState);
			setValue(editorState);
			setInitialValue(editorState);
		}
	};

	const saveContentSetting = async () => {
		const rawState = convertToRaw(content.value);
		const html = draftToHtml(rawState);
		if (rawState.blocks[0].text === '') {
			showNotification("Content can not be empty", "error");
			return;
		}
		
		await saveContent({ value: html });
	};

	const resetContentSetting = async () => {
		setValue(initialValue);
	};

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDBox>
									<MDTypography variant="h6" color="white" textAlign="middle">
										{label}
									</MDTypography>
								</MDBox>
								<MDBox
									sx={{
										display: "flex",
										gap: "8px",
									}}>
									<MDButton
										iconOnly={true}
										variant="contained"
										size="medium"
										color="secondary"
										sx={{
											display: "flex",
											gap: "4px",
										}}
										onClick={resetContentSetting}>
										<Icon fontSize="medium">restore</Icon>
									</MDButton>
									<MDButton
										iconOnly={true}
										variant="contained"
										size="medium"
										color="primary"
										sx={{
											display: "flex",
											gap: "4px",
										}}
										onClick={saveContentSetting}>
										<Icon fontSize="medium">save</Icon>
									</MDButton>
								</MDBox>
							</MDBox>
							<MDBox px={3} sx={{ minHeight: "350px" }} py={2}>
								<Editor
									editorState={value}
									// toolbarClassName="toolbarClassName"
									// wrapperClassName="wrapperClassName"
									// editorClassName="editorClassName"
									onEditorStateChange={onEditorChange}
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default ContentEditor;
