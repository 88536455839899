import Dashboard from "layouts/dashboard";
import Products from "layouts/products";
import Ingredients from "layouts/ingredients";
import FeedbackPage from "layouts/feedback";
import OrdersTables from "layouts/orders";
import SignIn from "layouts/authentication/sign-in";

import Icon from "@mui/material/Icon";
import SettingsTable from "layouts/settings";
import CategoryTable from "layouts/category";
import BlogSection from "layouts/news";
import ContentEditor from "layouts/settings/contentEditor";
import { addTermsAndConditions } from "service/settingsService";
import { getTermsAndConditions } from "service/settingsService";
import { getAllergens } from "service/settingsService";
import { addAllergens } from "service/settingsService";
import { getPrivacyPolicy } from "service/settingsService";
import { addPrivacyPolicy } from "service/settingsService";

const routes = [
	// {
	// 	type: "collapse",
	// 	name: "Home",
	// 	key: "dashboard",
	// 	icon: <Icon fontSize="small">show_chart</Icon>,
	// 	route: "/dashboard",
	// 	component: <Dashboard />,
	// },
	{
		type: "collapse",
		name: "Categories",
		key: "categories",
		icon: <Icon fontSize="small">category</Icon>,
		route: "/categories",
		component: <CategoryTable />,
	},
	{
		type: "collapse",
		name: "Ingredients",
		key: "ingredients",
		icon: <Icon fontSize="small">receipt_long</Icon>,
		route: "/ingredients",
		component: <Ingredients />,
	},
	{
		type: "collapse",
		name: "Products",
		key: "products",
		icon: <Icon fontSize="small">table_view</Icon>,
		route: "/products",
		component: <Products />,
	},
	{
		type: "collapse",
		name: "Orders",
		key: "orders",
		icon: <Icon fontSize="small">dashboard</Icon>,
		route: "/orders",
		component: <OrdersTables />,
	},
	{
		type: "collapse",
		name: "News",
		icon: <Icon fontSize="small">star</Icon>,
		key: "news",
		route: "/news",
		component: <BlogSection />,
	},
	{
		type: "collapse",
		name: "Feedback",
		key: "feedback",
		icon: <Icon fontSize="small">feedback</Icon>,
		route: "/feedback",
		component: <FeedbackPage />,
	},
	{
		type: "collapse",
		name: "General settings",
		key: "settings",
		icon: <Icon fontSize="small">settings</Icon>,
		route: "/settings",
		component: <SettingsTable />,
	},
	{
		type: "collapse",
		name: "Privacy Policy",
		key: "privacyPolicy",
		route: "/privacyPolicy",
		icon: <Icon fontSize="small">privacy_tip</Icon>,
		component: (
			<ContentEditor
				label="Privacy Policy"
				getContent={getPrivacyPolicy}
				saveContent={addPrivacyPolicy}></ContentEditor>
		),
	},
	{
		type: "collapse",
		name: "Terms & Conditions",
		key: "termsAndConditions",
		route: "/termsAndConditions",
		icon: <Icon fontSize="small">gavel</Icon>,
		component: (
			<ContentEditor
				label="Terms and Conditions"
				getContent={getTermsAndConditions}
				saveContent={addTermsAndConditions}></ContentEditor>
		),
	},
	{
		type: "collapse",
		name: "Allergens",
		key: "allergens",
		route: "/allergens",
		icon: <Icon fontSize="small">report</Icon>,
		component: (
			<ContentEditor label="Allergens" getContent={getAllergens} saveContent={addAllergens}></ContentEditor>
		),
	},
	{
		type: "collapse",
		name: "Sign out",
		key: "sign-out",
		icon: <Icon fontSize="small">logout</Icon>,
		route: "/authentication/sign-in",
		component: <SignIn />,
	},
	// {
	//   type: "collapse",
	//   name: "Sign Up",
	//   key: "sign-up",
	//   icon: <Icon fontSize="small">assignment</Icon>,
	//   route: "/authentication/sign-up",
	//   component: <SignUp />,
	// },
];

export default routes;
