import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function MDProperty({ label, value }) {
	return (
		<MDBox
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "start",
				alignItems: "start",
				padding: "10px",
				borderRadius: "5px",
			}}>
			<MDTypography sx={{ fontSize: "16px", fontWeight: "500" }}>{label}</MDTypography>
			<MDTypography sx={{ fontSize: "14px", marginBottom: "5px" }}>{value}</MDTypography>
		</MDBox>
	);
}

export default MDProperty;
