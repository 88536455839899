import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { getAllIngredients } from "service/ingredientService";
import { Checkbox, Divider, FormControl, FormControlLabel, Icon, InputLabel, MenuItem, Select } from "@mui/material";

const initialRow = {
	id: "",
	name: "",
	ProductIngredient: { qty: "", kcal: "", details: "", extra: false, extra_price: 0 },
};

const ChosenIngredientsForm = (props) => {
	const [ingredients, setIngredients] = useState([]);
	const [originalIngredients, setOriginalIngredients] = useState([]);
	const [rows, setRows] = useState([initialRow]);

	const addRow = () => {
		setRows((prevRows) => [...prevRows, initialRow]);
		setIngredients(originalIngredients.filter((a) => !rows.some((b) => b.id === a.id)));
	};

	const removeRow = (indexToRemove) => {
		setRows((rows) => rows.filter((row, index) => index !== indexToRemove));
		setIngredients(originalIngredients.filter((a) => !rows.some((b) => b.id === a.id)));
	};

	const handleSelectIngredients = () => {
		props.sendData(rows);
		props.setOpen(false);
	};

	useEffect(() => {
		async function getIngredients() {
			const result = await getAllIngredients();
			if (result.ingredients) {
				let filteredArray = [];
				const originalArray = result.ingredients.map((a) => ({ id: a.id, name: a.name }));
				if (props.selectedIngredientsData && props.selectedIngredientsData.length) {
					filteredArray = result.ingredients
						.filter((a) => !props.selectedIngredientsData.some((b) => b.id === a.id))
						.map((a) => ({ id: a.id, name: a.name }));
				} else {
					filteredArray = result.ingredients.map((a) => ({ id: a.id, name: a.name }));
				}
				setIngredients(filteredArray);
				setOriginalIngredients(originalArray);
				if (props.selectedIngredientsData && props.selectedIngredientsData.length > 0) {
					setRows(props.selectedIngredientsData);
				}
			}
		}
		getIngredients();
	}, []);

	return (
		<MDBox px={3} width={"100%"}>
			<MDBox mb={2} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
				<MDTypography variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
					Choose ingredients
				</MDTypography>
				<MDButton color="primary" iconOnly={true} onClick={() => addRow()}>
					<Icon>add</Icon>
				</MDButton>
			</MDBox>
			{rows.map((row, index) => (
				<MDBox key={"choose-ingredient-" + index}>
					<MDBox mb={4} display="flex" alignItems="center" sx={{ width: "100%", gap: "8px" }}>
						<MDBox
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								width: "100%",
								gap: "8px",
							}}>
							<MDBox
								sx={{
									display: "flex",
									flexDirection: "row",
									width: "100%",
									gap: "16px",
									justifyContent: "space-between",
								}}>
								<MDBox
									sx={{
										display: "flex",
										flexDirection: "row",
										width: "100%",
										gap: "16px",
									}}>
									<FormControl sx={{ width: "50%", height: "2.1375em" }}>
										<InputLabel id={`select-label-${index}`}>Ingredient</InputLabel>
										<Select
											labelId={`select-label-${index}`}
											id={`select-${index}`}
											sx={{
												width: "100%",
												height: "100%",
												"& .MuiSelect-outlined": {
													height: "100% !important",
												},
											}}
											value={row.id}
											onChange={(e) => {
												const id = e.target.value;
												const selectedIngredient = ingredients.find(
													(ingredient) => ingredient.id === id
												);
												if (selectedIngredient) {
													setRows((rows) =>
														rows.map((row, idx) =>
															idx === index
																? {
																		...row,
																		id: selectedIngredient.id,
																		name: selectedIngredient.name,
																  }
																: row
														)
													);
												}
											}}
											label="Ingredient">
											{originalIngredients.map((ingredient, index) => (
												<MenuItem
													key={"menu-ingredient-" + index}
													value={ingredient.id}
													disabled={!ingredients.some((o) => o.id === ingredient.id)}>
													{ingredient.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
									<FormControlLabel
										sx={{ width: "50%" }}
										control={
											<Checkbox
												checked={row.ProductIngredient.extra}
												onChange={(e) => {
													const value = e.target.checked;
													setRows((rows) =>
														rows.map((row, idx) =>
															idx === index
																? {
																		...row,
																		ProductIngredient: {
																			...row.ProductIngredient,
																			extra: value,
																		},
																  }
																: row
														)
													);
												}}
											/>
										}
										label="Extra"
									/>
								</MDBox>
								<MDButton
									iconOnly={true}
									color="secondary"
									onClick={() => removeRow(index)}
									sx={{ ml: 1 }}>
									<Icon>delete</Icon>
								</MDButton>
							</MDBox>
							<MDBox sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "8px" }}>
								<MDInput
									type="number"
									label="Quantity"
									sx={{ width: "25%" }}
									value={row.ProductIngredient.qty}
									onChange={(e) => {
										const value = e.target.value;
										setRows((rows) =>
											rows.map((row, idx) =>
												idx === index
													? {
															...row,
															ProductIngredient: {
																...row.ProductIngredient,
																qty: value,
															},
													  }
													: row
											)
										);
									}}
								/>
								<MDInput
									type="number"
									label="Kcal"
									sx={{ width: "25%" }}
									value={row.ProductIngredient.kcal}
									onChange={(e) => {
										const value = e.target.value;
										setRows((rows) =>
											rows.map((row, idx) =>
												idx === index
													? {
															...row,
															ProductIngredient: {
																...row.ProductIngredient,
																kcal: value,
															},
													  }
													: row
											)
										);
									}}
								/>
								{rows[index].ProductIngredient.extra && (
									<MDInput
										type="number"
										label="Extra price"
										value={row.ProductIngredient.extra_price}
										onChange={(e) => {
											const value = e.target.value;
											setRows((rows) =>
												rows.map((row, idx) =>
													idx === index
														? {
																...row,
																ProductIngredient: {
																	...row.ProductIngredient,
																	extra_price: value,
																},
														  }
														: row
												)
											);
										}}
									/>
								)}
							</MDBox>
							<MDBox sx={{ display: "flex", flexDirection: "row", width: "100%", gap: "8px" }}>
								<MDInput
									type="text"
									label="Details"
									sx={{ width: "100%" }}
									value={row.ProductIngredient.details}
									multiline
									rows={3}
									onChange={(e) => {
										const value = e.target.value;
										setRows((rows) =>
											rows.map((row, idx) =>
												idx === index
													? {
															...row,
															ProductIngredient: {
																...row.ProductIngredient,
																details: value,
															},
													  }
													: row
											)
										);
									}}
								/>
							</MDBox>
						</MDBox>
					</MDBox>
					<Divider></Divider>
				</MDBox>
			))}
			<MDBox mb={2}>
				<MDButton type="button" variant="contained" color="secondary" onClick={handleSelectIngredients}>
					Add ingredients
				</MDButton>
			</MDBox>
		</MDBox>
	);
};

export default ChosenIngredientsForm;
