import React, { useContext, useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { addNews } from "service/blogService";
import { editNews } from "service/blogService";
import { Icon } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import ImageUpload from "components/MDImageUpload";
import SimpleReactValidator from "simple-react-validator";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";

const BlogForm = (props) => {
	const validator = useRef(new SimpleReactValidator());
	const showNotification = useContext(SnackbarContext);
	const [title, setTitle] = useState("");
	const [titleError, setTitleError] = useState(false);
	const [contentDetails, setContentDetails] = useState({});
	const [detailsValue, setDetailsValue] = useState("");
	const [details, setDetails] = useState(EditorState.createEmpty());
	const [tagsError, setTagsError] = useState(false);
	const [initialDetails, setInitialDetails] = useState(EditorState.createEmpty());
	const [image, setImage] = useState(
		props.blog && props.blog.id ? `/api/image/blogs/o/webp/${props.blog.id}.webp` : null
	);
	const [tags, setTags] = useState("");
	const [isFormChanged, setIsFormChanged] = useState(false);

	useEffect(() => {
		if (props.blog) {
			setTitle(props.blog.title);
			setTags(props.blog.tags);
			setContentDetails(props.blog.details);
			setEditorDefaultValue(props.blog.details);
			setImage(props.blog.image);
		}
	}, [props.blog]);

	const setEditorDefaultValue = (html) => {
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
			const editorState = EditorState.createWithContent(contentState);
			setDetails(editorState);
			setInitialDetails(editorState);
		}
	};

	const onEditorChange = (event) => {
		const content = event.getCurrentContent();
		setDetails(event);
		setContentDetails((prevState) => ({ ...prevState, value: content }));
		const detailsValue = convertToRaw(contentDetails.value).blocks[0].text;
		setDetailsValue(detailsValue);
		validator.current.showMessageFor("details");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const resetContentSetting = async () => {
		setDetails(initialDetails);
	};

	const validateTitle = (event) => {
		setTitle(event.target.value);
		setTitleError(!validator.current.check(event.target.value, "required"));
		validator.current.showMessageFor("title");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const validateTags = (event) => {
		setTags(event.target.value);
		setTagsError(!validator.current.check(event.target.value, "required"));
		validator.current.showMessageFor("tags");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const handleBlog = async () => {
		const rawState = convertToRaw(contentDetails.value);
		const html = draftToHtml(rawState);

		if (!validator.current.allValid()) {
			showNotification("Need to complete required fields", "error");
			return;
		}

		const blog = {
			title: title,
			details: html,
			tags: tags,
			image: image,
		};

		let result = null;
		if (props.blog) {
			blog.id = props.blog.id;
			result = await editNews(blog);
		} else {
			result = await addNews(blog);
		}
		if (result) {
			const newblogs = JSON.parse(JSON.stringify(props.blogs));
			newblogs.push(result);
			props.setBlogs(newblogs);
		}
		if (result) {
			const copyBlogs = JSON.parse(JSON.stringify(props.blogs));
			const currentBlogIndex = copyBlogs.findIndex((b) => b.id === blog.id);
			if (currentBlogIndex !== -1) {
				copyBlogs[currentBlogIndex] = blog;
			} else {
				copyBlogs.push(blog);
			}
			props.setBlogs(copyBlogs);
			props.setOpen(false);
		}
	};

	return (
		<>
			<MDTypography px={3} variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
				{props.blog && props.blog.id ? "Edit post" : "Create post"}
			</MDTypography>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							gap: "8px",
							flexDirection: "row",
						}}>
						<MDBox
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "65%",
								flexDirection: "column",
							}}>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="text"
									label="Title"
									value={title}
									error={titleError}
									onChange={(event) => validateTitle(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("title", title, "required")}
								</MDTypography>
							</MDBox>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="text"
									label="Tags"
									error={tagsError}
									value={tags}
									onChange={(event) => validateTags(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("tags", tags, "required")}
								</MDTypography>
							</MDBox>
						</MDBox>
						<MDBox
							mb={2}
							sx={{
								width: "35%",
								justifyContent: "center",
								display: "flex",
								alignItems: "center",
								minHeight: "150px",
							}}>
							<ImageUpload defaultImage={image} onImageUpload={(image) => setImage(image)}></ImageUpload>
						</MDBox>
					</MDBox>
					<MDBox sx={{ minHeight: "350px" }} py={2}>
						<Editor editorState={details} onEditorStateChange={onEditorChange} />
					</MDBox>
					<MDTypography sx={{ color: "red", fontSize: "12px" }}>
						{validator.current.message("details", detailsValue, "required")}
					</MDTypography>
					<MDBox mt={2} sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
						<MDButton type="button" variant="contained" size="medium" color="primary" onClick={handleBlog}>
							<Icon fontSize="large">save</Icon>
							SAVE
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</>
	);
};

export default BlogForm;
