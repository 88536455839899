import React, { useState, useEffect, useContext, useRef } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { addCategory, editCategory } from "service/categoryService";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { Icon } from "@mui/material";
import ImageUpload from "components/MDImageUpload";
import SimpleReactValidator from "simple-react-validator";

const CategoryForm = (props) => {
	const showNotification = useContext(SnackbarContext);
	const validator = useRef(new SimpleReactValidator());
	const btnStyle = { marginTop: 15, marginLeft: "28%" };
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [categoryName, setCategoryName] = useState({ value: "", error: true });
	const [image, setImage] = useState(
		props.currentCategory && props.currentCategory.id
			? `/api/image/categories/o/webp/${props.currentCategory.id}.webp`
			: null
	);
	const [categoryDetails, setCategoryDetails] = useState({ value: "", error: true });

	useEffect(() => {
		if (props.currentCategory) {
			setCategoryName({ value: props.currentCategory.name, error: false });
			setCategoryDetails({ value: props.currentCategory.details, error: false });
			setImage(props.currentCategory.image);
		}
	}, [props.currentCategory]);

	const handleCategorySubmit = async () => {
		if (!validator.current.allValid()) {
			showNotification("You need to complete all required fields", "error");
			return;
		}

		const category = {
			name: categoryName.value,
			image: image,
			details: categoryDetails.value,
		};

		let result;
		if (props.currentCategory) {
			category.id = props.currentCategory.id;
			try {
				result = await editCategory(category);
				showNotification("The category was successfully edited.", "success");
			} catch (err) {
				showNotification("There was a problem editing the category.", "error");
			}
		} else {
			try {
				result = await addCategory(category);
				showNotification("The category was successfully created.", "success");
			} catch (err) {
				showNotification("There was a problem creating the category.", "error");
			}
		}

		if (result) {
			let copyCategories = JSON.parse(JSON.stringify(props.categories));
			const currentCategoriesIndex = copyCategories.findIndex((c) => c.id === category.id);
			if (currentCategoriesIndex !== -1) {
				copyCategories[currentCategoriesIndex] = category;
			} else {
				copyCategories.push(category);
			}
			props.setCategories(copyCategories);
			props.setOpen(false);
		}
	};

	const validateCategoryName = (event) => {
		setCategoryName({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("name");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const validateCategoryDetails = (event) => {
		setCategoryDetails({
			value: event.target.value,
			error: !validator.current.check(event.target.value, "required"),
		});
		validator.current.showMessageFor("details");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	return (
		<MDBox sx={{ width: "100%" }}>
			<MDTypography px={3} variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
				{props.currentCategory && props.currentCategory.id ? "Edit category" : "Create category"}
			</MDTypography>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form" sx={{ width: "100%" }}>
					<MDBox
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							gap: "8px",
						}}>
						<MDBox mb={2} sx={{ width: "65%" }}>
							<MDInput
								type="text"
								label="Name"
								error={categoryName.error}
								value={categoryName.value}
								onChange={(event) => validateCategoryName(event)}
								fullWidth
							/>
							<MDTypography sx={{ color: "red", fontSize: "12px" }}>
								{validator.current.message("name", categoryName.value, "required")}
							</MDTypography>
						</MDBox>
						<MDBox
							mb={2}
							sx={{
								width: "35%",
								justifyContent: "center",
								display: "flex",
								alignItems: "center",
								minHeight: "150px",
							}}>
							<ImageUpload defaultImage={image} onImageUpload={(image) => setImage(image)}></ImageUpload>
						</MDBox>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Details"
							value={categoryDetails.value}
							error={categoryDetails.error}
							onChange={(event) => validateCategoryDetails(event)}
							multiline
							rows={5}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("details", categoryDetails.value, "required")}
						</MDTypography>
					</MDBox>
					<MDBox mt={2} sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
						<MDButton
							type="button"
							style={btnStyle}
							variant="contained"
							color="primary"
							size={"medium"}
							onClick={handleCategorySubmit}>
							<Icon>save</Icon>
							SAVE
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default CategoryForm;
