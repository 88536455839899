import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import customAxios from "utils/axios";
import { useContext, useEffect, useState } from "react";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import IngredientForm from "../ingredientForm";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import IngredientView from "../ingredientView";
import DataTable from "examples/Tables/DataTable";

export default function IngredientsDataTable(props) {
	const showNotification = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [ingredient, setIngredient] = useState("");
	const [ingredients, setIngredients] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	useEffect(() => {
		setIngredients(props.ingredients);
	}, [props.ingredients]);

	const handleClickOpen = (item) => {
		setIngredient(item);
		setOpen(true);
	};

	const handleConfirmationOpen = (item) => {
		setIngredient(item);
		setConfirmationOpen(true);
	};

	const handleView = (item) => {
		setIngredient(item);
		setOpenView(true);
	};

	const handleIngredientDelete = async (item) => {
		try {
			const response = await customAxios.delete(`/api/ingredient/${item.id}`);
			if (response) {
				setIngredients(ingredients.filter((x) => x.id !== item.id));
				showNotification("The ingredient was successfully removed.", "success");
				setConfirmationOpen(false);
			}
		} catch (err) {
			showNotification("There was a problem removing the ingredient. Please try again.", "error");
		}
	};

	const Ingredient = ({ image, name, details }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDBox lineHeight={1}>
				<MDTypography display="block" variant="button" fontWeight="medium">
					{name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : "-"}
				</MDTypography>
			</MDBox>
		</MDBox>
	);

	const Unit = ({ unit }) => (
		<MDBox lineHeight={1} textAlign="left">
			<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
				{unit}
			</MDTypography>
		</MDBox>
	);

	const Detalis = ({ details }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDBox lineHeight={1}>
				<MDTypography
					style={{
						display: "block",
						width: "250px",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden",
					}}
					variant="caption">
					{details ? details : "-"}
				</MDTypography>
			</MDBox>
		</MDBox>
	);

	return (
		<>
			<DataTable
				table={{
					columns: [
						{ Header: "name", accessor: "name", align: "left" },
						{ Header: "unit", accessor: "unit", align: "center" },
						{ Header: "details", accessor: "details", width: "*", align: "center" },
						{ Header: "actions", accessor: "action", align: "center" },
					],
					rows:
						ingredients && ingredients.length > 0
							? ingredients.map((item) => ({
									name: <Ingredient image={item.image} name={item.name} details={item.name} />,
									unit: <Unit unit={item.unit} />,
									details: <Detalis details={item.details}></Detalis>,
									action: (
										<MDBox lineHeight={1} sx={{ display: "flex", gap: "8px" }} textAlign="left">
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="secondary"
												fontWeight="medium"
												onClick={(e) => handleConfirmationOpen(item)}>
												<Icon fontSize="medium">delete</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="primary"
												fontWeight="medium"
												onClick={(e) => handleClickOpen(item)}>
												<Icon fontSize="medium">edit</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="info"
												fontWeight="medium"
												onClick={(e) => handleView(item)}>
												<Icon fontSize="medium">visibility</Icon>
											</MDButton>
										</MDBox>
									),
							  }))
							: [],
				}}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<IngredientForm
						currentIngredient={ingredient}
						setIngredient={setIngredient}
						ingredients={ingredients}
						setIngredients={setIngredients}
						open={open}
						setOpen={setOpen}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				disableClose={true}
				component={
					<ConfirmationComponent
						message={
							"Are you certain you wish to remove this ingredient? Please remember, this action cannot be undone."
						}
						handleConfirm={() => handleIngredientDelete(ingredient)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
			<ResponsiveDialog
				width={"300px"}
				open={openView}
				setOpen={setOpenView}
				component={<IngredientView currentIngredient={ingredient} />}></ResponsiveDialog>
		</>
	);
}
