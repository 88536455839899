import customAxios from "utils/axios";

export async function getAllFeedbacks() {
	try {
		const response = await customAxios.get("/api/feedbacks");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function addFeedback(data) {
	const response = await customAxios.post(`/api/feedbacks`, {
		name: data.name,
		unit: data.unit,
	});
	return response.data;
}

export async function editFeedback(data) {
	const response = await customAxios.put(`/api/feedbacks`, { data });
	return response.data;
}

export async function deleteFeedback(id) {
	const response = await customAxios.delete(`/api/feedbacks/${id}`);
	return response.data;
}
