import customAxios from "utils/axios";

export async function getAllProducts() {
	try {
		const response = await customAxios.get("/api/product?ingredients=true");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function addProduct(data) {
	const formData = new FormData();
	formData.append("name", data.name);
	formData.append("details", data.details);
	formData.append("price", data.price);
	if (data.sale_price) {
		formData.append("sale_price", data.sale_price);
	}
	formData.append("qty", data.qty);
	formData.append("unit", data.unit);
	formData.append("allergens", data.allergens);
	formData.append("status", data.status);
	formData.append("kcal", data.kcal);
	formData.append("recommended", data.recommended);
	formData.append("favorite", data.favorite);
	formData.append("image", data.image);
	formData.append("categoryId", data.categoryId);
	formData.append("ingredients", JSON.stringify(data.ingredients));
	const response = await customAxios.post(`/api/product`, formData, {
		headers: { "content-type": "application/x-www-form-urlencoded" },
	});
	return response.data;
}

export async function updateProduct(data, id) {
	const formData = new FormData();
	formData.append("id", data.id);
	formData.append("name", data.name);
	if (data.details) {
		formData.append("details", data.details);
	}
	formData.append("price", data.price);
	if (data.sale_price) {
		formData.append("sale_price", data.sale_price);
	}
	formData.append("qty", data.qty);
	formData.append("unit", data.unit);
	if (data.allergens) {
		formData.append("allergens", data.allergens);
	}
	formData.append("status", data.status);
	formData.append("kcal", data.kcal);
	formData.append("recommended", data.recommended);
	formData.append("favorite", data.favorite);
	formData.append("image", data.image);
	formData.append("categoryId", data.categoryId);
	formData.append("ingredients", JSON.stringify(data.ingredients));
	const response = await customAxios.put(`/api/product`, formData, {
		headers: { "content-type": "application/x-www-form-urlencoded" },
	});

	return response.data;
}

export async function deleteProduct(id) {
	const response = await customAxios.delete(`/api/product/${id}`);
	return response.data;
}
