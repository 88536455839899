import React, { useContext, useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import customAxios from "utils/axios";
import ProductForm from "../productForm";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import ChosenIngredientsForm from "../productForm/productIngredientsChoose";
import ProductView from "../productView";
import DataTable from "examples/Tables/DataTable";
import brandWhite from "assets/images/logo-ct.png";

export default function ProductsDataTable(props) {
	const showNotification = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [product, setProduct] = useState({});
	const [products, setProducts] = useState([]);
	const [openIngredients, setOpenIngredients] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [selectedIngredientsData, setSelectedIngredientsData] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);

	const getSelectedIngredientsData = (data) => {
		setSelectedIngredientsData(data);
	};

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	useEffect(() => {
		setProducts(props.products);
	}, [props.products]);

	const handleClickOpen = (item) => {
		setProduct(item);
		setSelectedIngredientsData(item.ingredients);
		setOpen(true);
	};

	const handleConfirmationOpen = (item) => {
		setProduct(item);
		setConfirmationOpen(true);
	};

	const handleDelete = async (item) => {
		try {
			const response = await customAxios.delete(`/api/product/${item.id}`);
			if (response) {
				setProducts(products.filter((x) => x.id !== item.id));
				setConfirmationOpen(false);
				showNotification("The product was successfully removed.", "success");
			}
		} catch (err) {
			showNotification("There was a problem removing the product. Please try again.", "error");
		}
	};

	const handleView = (item) => {
		setProduct(item);
		setOpenView(true);
	};

	const Product = ({ image, name, description }) => {
		const [isLoading, setIsLoading] = useState(true);

		useEffect(() => {
			const img = new Image();
			img.src = image;
			img.onload = () => setIsLoading(false);
		}, [image]);

		return (
			<MDBox display="flex" alignItems="center" lineHeight={1}>
				{isLoading ? (
					<img
						style={{
							width: "100px",
							height: "auto",
							maxHeight: "100px",
							minHeight: "100px",
							objectFit: "contain",
						}}
						src={brandWhite}
						alt="Placeholder"></img>
				) : (
					<img
						style={{
							width: "100px",
							height: "auto",
							maxHeight: "100px",
							minHeight: "100px",
							objectFit: "contain",
						}}
						src={image}
						alt={name}></img>
				)}
				<MDBox ml={2} lineHeight={1}>
					<MDTypography display="block" variant="button" fontWeight="medium">
						{name}
					</MDTypography>
					<MDTypography
						style={{
							display: "block",
							width: "150px",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							overflow: "hidden",
						}}
						variant="caption">
						{description}
					</MDTypography>
				</MDBox>
			</MDBox>
		);
	};

	const Price = ({ pricePerUnit, currency }) => (
		<MDBox lineHeight={1} textAlign="left">
			<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
				{pricePerUnit ? pricePerUnit + " " + currency : "-"}
			</MDTypography>
		</MDBox>
	);

	const columns = [
		{ Header: "name", accessor: "name", align: "left" },
		{ Header: "category", accessor: "category", align: "center" },
		{ Header: "quantity", accessor: "quantity", align: "center" },
		{ Header: "price", accessor: "price", align: "left" },
		{ Header: "sale price", accessor: "sale_price", align: "left" },
		{ Header: "actions", accessor: "action", align: "center" },
	];

	return (
		<>
			<DataTable
				table={{
					columns,
					rows:
						products && products.length > 0
							? products.map((item) => ({
									name: (
										<Product
											image={`/api/image/products/t/webp/${item.id}.webp`}
											name={item.name}
											description={item.details}
										/>
									),
									category: (
										<MDTypography
											display="block"
											variant="caption"
											color="text"
											fontWeight="medium">
											{item.category ? item.category.name : "-"}
										</MDTypography>
									),
									quantity: (
										<MDTypography
											display="block"
											variant="caption"
											color="text"
											fontWeight="medium">
											{item.qty} {item.unit}
										</MDTypography>
									),
									price: <Price pricePerUnit={item.price} currency={"RON"} />,
									sale_price: <Price pricePerUnit={item.sale_price} currency={"RON"} />,
									action: (
										<MDBox lineHeight={1} sx={{ display: "flex", gap: "8px" }} textAlign="left">
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="secondary"
												fontWeight="medium"
												onClick={(e) => handleConfirmationOpen(item)}>
												<Icon fontSize="medium">delete</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="primary"
												fontWeight="medium"
												onClick={(e) => handleClickOpen(item)}>
												<Icon fontSize="medium">edit</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="info"
												fontWeight="medium"
												onClick={(e) => handleView(item)}>
												<Icon fontSize="medium">visibility</Icon>
											</MDButton>
										</MDBox>
									),
							  }))
							: [],
				}}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<ProductForm
						products={products}
						setProducts={setProducts}
						currentProduct={product}
						openIngredients={openIngredients}
						setOpenIngredients={setOpenIngredients}
						selectedIngredientsData={selectedIngredientsData}
						setSelectedIngredientsData={setSelectedIngredientsData}
						setProduct={setProduct}
						setOpen={setOpen}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				disableClose={true}
				component={
					<ConfirmationComponent
						message={
							"Are you certain you wish to remove this product? Please remember, this action cannot be undone."
						}
						handleConfirm={() => handleDelete(product)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
			<ResponsiveDialog
				minWidth={"70%"}
				open={openIngredients}
				setOpen={setOpenIngredients}
				component={
					<ChosenIngredientsForm
						selectedIngredientsData={selectedIngredientsData}
						setOpen={setOpenIngredients}
						sendData={getSelectedIngredientsData}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				width={"300px"}
				open={openView}
				setOpen={setOpenView}
				component={<ProductView currentProduct={product} />}></ResponsiveDialog>
		</>
	);
}
