import { Checkbox, FormControlLabel, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import MDTypography from "components/MDTypography";
import { useContext, useEffect, useState } from "react";
import customAxios from "utils/axios";
import OrderProductsView from "../orderProductsView";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import DataTable from "examples/Tables/DataTable";

export default function OrdersDataTable(props) {
	const [orders, setOrders] = useState([]);
	const showNotification = useContext(SnackbarContext);
	const [openView, setOpenView] = useState(false);
	const [selectedOrder, setOrder] = useState({});

	useEffect(() => {
		setOrders(props.orders);
	}, [props.orders]);

	const handleView = (item) => {
		setOrder(item);
		setOpenView(true);
	};

	const Order = ({ id }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDBox lineHeight={1}>
				<MDTypography display="block" variant="button" fontWeight="medium">
					{id}
				</MDTypography>
			</MDBox>
		</MDBox>
	);

	const TotalPrice = ({ totalPrice }) => (
		<MDBox lineHeight={1} textAlign="left">
			<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
				{totalPrice} RON
			</MDTypography>
		</MDBox>
	);

	const Type = ({ type }) => (
		<MDBox lineHeight={1} textAlign="left">
			<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
				{type ? type.charAt(0).toUpperCase() + type.slice(1).toLowerCase() : "-"}
			</MDTypography>
		</MDBox>
	);

	const Status = ({ item }) => {
		const [activeStatus, setActiveStatus] = useState("");

		useEffect(() => {
			if (item.status) {
				setActiveStatus(item.status);
				// You can also make an initial API request here if necessary
			}
		}, [item.status]);

		const handleChange = async (event) => {
			const status = event.target.name;

			if (status === activeStatus) {
				setActiveStatus("");
			} else {
				item.status = status;
				setActiveStatus(status);
			}
			const data = { id: item.id, status: status };
			try {
				const response = await customAxios.put(`/api/order/status`, data);
				if (response) {
					showNotification("Order status was successfully changed.", "success");
				}
			} catch (err) {
				showNotification("There was a problem with order status change.", "error");
			}
		};

		return (
			<MDBox
				lineHeight={1}
				sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "row" }}>
				<FormControlLabel
					control={<Checkbox checked={activeStatus === "new"} onChange={handleChange} name="new" />}
					label={
						<MDTypography variant="span" sx={{ fontSize: "13px" }} color="info">
							New
						</MDTypography>
					}
				/>
				<FormControlLabel
					control={
						<Checkbox checked={activeStatus === "completed"} onChange={handleChange} name="completed" />
					}
					label={
						<MDTypography variant="span" sx={{ fontSize: "13px" }} color="success">
							Completed
						</MDTypography>
					}
				/>
				<FormControlLabel
					control={<Checkbox checked={activeStatus === "canceled"} onChange={handleChange} name="canceled" />}
					label={
						<MDTypography variant="span" sx={{ fontSize: "13px" }} color="error">
							Canceled
						</MDTypography>
					}
				/>
			</MDBox>
		);
	};

	return (
		<>
			<DataTable
				table={{
					columns: [
						{ Header: "identifier", accessor: "identifier", align: "left" },
						{ Header: "type", accessor: "type", align: "center" },
						{ Header: "status", accessor: "status", align: "center" },
						{ Header: "total price", accessor: "total", align: "center" },
						{ Header: "actions", accessor: "actions", align: "center" },
					],
					rows:
						orders && orders.length > 0
							? orders.map((order) => ({
									identifier: <Order id={order.identifier} />,
									type: <Type type={order.type}></Type>,
									status: <Status item={order}></Status>,
									total: <TotalPrice totalPrice={order.totalPrice} />,
									actions: (
										<MDBox lineHeight={1} sx={{ display: "flex", gap: "8px" }} textAlign="left">
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="info"
												fontWeight="medium"
												onClick={(e) => handleView(order)}>
												<Icon fontSize="medium">visibility</Icon>
											</MDButton>
										</MDBox>
									),
							  }))
							: [],
				}}
				showTotalEntries={false}
				isSorted={false}
				noEndBorder
				entriesPerPage={false}
			/>
			<ResponsiveDialog
				width={"300px"}
				open={openView}
				setOpen={setOpenView}
				component={<OrderProductsView order={selectedOrder} />}></ResponsiveDialog>
		</>
	);
}
