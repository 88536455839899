import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import Dialog from "@material-ui/core/Dialog";

// Data
import feedbackData from "layouts/feedback/data/feedbackData";
import { getAllFeedbacks } from "service/feedbackService";
import AddFeedbackForm from "./addPopup";

function FeedbackPage() {
	const [open, setOpen] = useState(false);
	const [feedbacks, setFeedbacks] = useState([]);
	const { columns, rows } = feedbackData(feedbacks);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		async function getFeedback() {
			const result = await getAllFeedbacks();
			if (result) {
				setFeedbacks(result);
			}
		}

		getFeedback();
	}, []);

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white">
									Feedback
									<Dialog aria-labelledby="customized-dialog-title" open={open}>
										<Icon fontSize="small" onClick={handleClose} style={{ cursor: "pointer" }}>
											close
										</Icon>
										<AddFeedbackForm />
									</Dialog>
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default FeedbackPage;
