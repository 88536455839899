import React, { useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { addIngredient } from "service/ingredientService";
import { editIngredient } from "service/ingredientService";
import { Icon } from "@mui/material";
import SimpleReactValidator from "simple-react-validator";

const IngredientForm = (props) => {
	const btnStyle = { marginTop: 15, marginLeft: "28%" };
	const validator = useRef(new SimpleReactValidator());
	const [name, setName] = useState({value: "", error: false});
	const [unit, setUnit] = useState({value: "", error: false});
	const [details, setDetails] = useState({value: "", error: false});
	const [ isFormChanged, setIsFormChanged] = useState(false);

	useEffect(() => {
		if (props.currentIngredient) {
			setName({ value: props.currentIngredient.name, error: false });
			setUnit({ value: props.currentIngredient.unit, error: false });
			setDetails({ value: props.currentIngredient.details, error: false });
		}
	}, [props.currentIngredient]);

	const handleIngredient = async () => {
		let result;
		const ingredient = {
			name: name.value,
			unit: unit.value,
			details: details.value,
		};

		if (props.currentIngredient) {
			ingredient.id = props.currentIngredient.id;
			result = await editIngredient(ingredient);
		} else {
			result = await addIngredient(ingredient);
		}

		if (result) {
			const copyIngredients = JSON.parse(JSON.stringify(props.ingredients));
			const currentIngredientIndex = copyIngredients.findIndex((i) => i.id === ingredient.id);
			if (currentIngredientIndex !== -1) {
				copyIngredients[currentIngredientIndex] = ingredient;
			} else {
				copyIngredients.push(ingredient);
			}
			props.setIngredients(copyIngredients);
		}
		props.setOpen(false);
	};

	const validateName = (event) => {
		setName({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("name");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	}

	const validateUnit = (event) => {
		setUnit({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("unit");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	}

	const validateDetails = (event) => {
		setDetails({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("details");
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	}

	return (
		<MDBox sx={{ width: "100%" }}>
			<MDTypography px={3} variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
				{props.currentIngredient && props.currentIngredient.id ? "Edit ingredient" : "Create ingredient"}
			</MDTypography>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Name"
							value={name.value}
							error={name.error}
							onChange={(event) => validateName(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("name", name.value, "required")}
						</MDTypography>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Unit"
							value={unit.value}
							error={unit.error}
							onChange={(event) => validateUnit(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("unit", unit.value, "required")}
						</MDTypography>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Details"
							value={details.value}
							error={details.error}
							multiline
							rows={5}
							onChange={(event) => validateDetails(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("details", details.value, "required")}
						</MDTypography>
					</MDBox>
					<MDBox mt={2} sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
						<MDButton
							type="button"
							style={btnStyle}
							variant="contained"
							color="primary"
							onClick={(event) => handleIngredient(event)}>
							<Icon fontSize="medium">save</Icon>
							SAVE
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default IngredientForm;
