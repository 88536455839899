import React from "react";
import MDBox from "components/MDBox";
import MDProperty from "components/MDProperty";

const CategoryView = (props) => {
	const { currentCategory } = props;

	return (
		<MDBox sx={{ width: "100%", borderRadius: "10px" }}>
			{currentCategory && (
				<MDBox pt={4} pb={3} px={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<MDBox component="form" role="form" sx={{ width: "100%" }}>
						<MDBox mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<MDBox>
								<MDProperty label={"Name"} value={currentCategory.name} />
							</MDBox>
							<img
								src={`/api/image/categories/o/webp/${currentCategory.id}.webp`}
								alt={currentCategory.name}
								style={{
									maxHeight: "150px",
									width: "auto",
									border: "1px solid #ddd",
								}}
							/>
						</MDBox>
						<MDBox mb={2} sx={{ width: "100%" }}>
							<MDProperty label={"Details"} value={currentCategory.details} />
						</MDBox>
					</MDBox>
				</MDBox>
			)}
		</MDBox>
	);
};

export default CategoryView;
