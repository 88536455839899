import React from "react";
import MDTypography from "components/MDTypography";

export default function OrderProductsDataTable(data) {
	return {
		columns: [
			{ Header: "name", accessor: "name", align: "left" },
		    { Header: "category", accessor: "category", align: "center" },
		    { Header: "quantity", accessor: "quantity", align: "center" },
		    { Header: "price", accessor: "price", align: "left" },
		],

		rows: data.map((item) => ({
			name: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.name}
				</MDTypography>
			),
			quantity: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.orderProducts.qty}
				</MDTypography>
			),
			category: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.category.name}
				</MDTypography>
			),
			price: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.price}
				</MDTypography>
			),
		})),
	};
}
