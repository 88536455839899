import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import customAxios from "utils/axios";
import CategoryForm from "../categoryForm";
import ResponsiveDialog from "../../generalTable/responsiveDialog";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import CategoryView from "../categoryView";
import DataTable from "examples/Tables/DataTable";
import brandWhite from "assets/images/logo-ct.png";

export default function CategoryDataTable(props) {
	const showNotification = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [category, setCategory] = useState("");
	const [categories, setCategories] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);

	useEffect(() => {
		setCategories(props.categories);
	}, [props.categories]);

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	const handleClickOpen = (item) => {
		setCategory(item);
		setOpen(true);
	};

	const handleConfirmationOpen = (item) => {
		setCategory(item);
		setConfirmationOpen(true);
	};

	const handleView = (item) => {
		setCategory(item);
		setOpenView(true);
	};

	const handleCategoryDelete = async (item) => {
		try {
			const response = await customAxios.delete(`/api/category/${item.id}`);
			if (response) {
				setCategories(categories.filter((x) => x.id !== item.id));
				setConfirmationOpen(false);
				showNotification("The category was successfully removed.", "success");
			}
		} catch (err) {
			showNotification("There was a problem removing the category. Please try again.", "error");
		}
	};

	const Category = ({ name, image }) => {
		const [isLoading, setIsLoading] = useState(true);

		useEffect(() => {
			const img = new Image();
			img.src = image;
			img.onload = () => setIsLoading(false);
		}, [image]);

		return (
			<MDBox display="flex" alignItems="center" lineHeight={1}>
				{isLoading ? (
					<img
						style={{
							width: "100px",
							height: "auto",
							maxHeight: "100px",
							minHeight: "100px",
							objectFit: "contain",
						}}
						src={brandWhite}
						alt="Placeholder"></img>
				) : (
					<img
						style={{
							width: "100px",
							height: "auto",
							maxHeight: "100px",
							minHeight: "100px",
							objectFit: "contain",
						}}
						src={image}
						alt={name}></img>
				)}
				<MDBox ml={2} lineHeight={1}>
					<MDTypography display="block" variant="button" fontWeight="medium">
						{name}
					</MDTypography>
				</MDBox>
			</MDBox>
		);
	};

	const Detalis = ({ details }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDBox lineHeight={1}>
				<MDTypography
					style={{
						display: "block",
						width: "250px",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden",
					}}
					variant="caption">
					{details}
				</MDTypography>
			</MDBox>
		</MDBox>
	);

	return (
		<>
			<DataTable
				table={{
					columns: [
						{ Header: "name", accessor: "name", align: "left" },
						{ Header: "details", accessor: "details", width: "*", align: "center" },
						{ Header: "actions", accessor: "action", align: "center" },
					],
					rows:
						categories && categories.length > 0
							? categories.map((item) => ({
									name: (
										<Category
											image={`/api/image/categories/t/webp/${item.id}.webp`}
											name={item.name}
										/>
									),
									details: <Detalis details={item.details} />,
									action: (
										<>
											<MDBox lineHeight={1} sx={{ display: "flex", gap: "8px" }} textAlign="left">
												<MDButton
													iconOnly={true}
													variant="contained"
													size="medium"
													color="secondary"
													fontWeight="medium"
													onClick={(e) => handleConfirmationOpen(item)}>
													<Icon fontSize="medium">delete</Icon>
												</MDButton>
												<MDButton
													iconOnly={true}
													variant="contained"
													size="medium"
													color="primary"
													fontWeight="medium"
													onClick={(e) => handleClickOpen(item)}>
													<Icon fontSize="small">edit</Icon>
												</MDButton>
												<MDButton
													iconOnly={true}
													variant="contained"
													size="medium"
													color="info"
													fontWeight="medium"
													onClick={(e) => handleView(item)}>
													<Icon fontSize="medium">visibility</Icon>
												</MDButton>
											</MDBox>
										</>
									),
							  }))
							: [],
				}}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<CategoryForm
						currentCategory={category}
						setCategory={setCategory}
						categories={categories}
						setCategories={setCategories}
						open={open}
						setOpen={setOpen}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				component={
					<ConfirmationComponent
						message={
							"Are you certain you wish to remove this category? Please remember, this action cannot be undone."
						}
						handleConfirm={() => handleCategoryDelete(category)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
			<ResponsiveDialog
				width={"300px"}
				open={openView}
				setOpen={setOpenView}
				component={<CategoryView currentCategory={category} />}></ResponsiveDialog>
		</>
	);
}
