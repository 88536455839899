import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Checkbox, Icon } from "@mui/material";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { useContext, useEffect, useState } from "react";
import customAxios from "utils/axios";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import FeedbackView from "../feedbackView";

export default function FeedbackDataTable(data) {
	const showNotification = useContext(SnackbarContext);
	const [openView, setOpenView] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [feedbacks, setFeedbacks] = useState([]);
	const [feedback, setFeedback] = useState({});

	const FeedbackName = ({ name }) => (
		<MDBox display="flex" alignItems="center" lineHeight={1}>
			<MDBox lineHeight={1}>
				<MDTypography display="block" variant="button" fontWeight="medium">
					{name}
				</MDTypography>
			</MDBox>
		</MDBox>
	);

	useEffect(() => {
		setFeedbacks(data);
	}, [data]);

	const handleConfirmationOpen = (item) => {
		setFeedback(item);
		setConfirmationOpen(true);
	};

	const handleView = (item) => {
		setFeedback(item);
		setOpenView(true);
	};

	const handleFeedbackDelete = async (item) => {
		try {
			const response = await customAxios.delete(`/api/feedback/${item.id}`);
			if (response) {
				setFeedbacks(feedbacks.filter((x) => x.id !== item.id));
				showNotification("The feedback was successfully removed.", "success");
				setConfirmationOpen(false);
			}
		} catch (err) {
			showNotification("There was a problem removing the feedback. Please try again.", "error");
		}
	};

	const Favorite = ({ item }) => {
		const [favorite, setFavorite] = useState(false);

		useEffect(() => {
			if (item.favorite) {
				setFavorite(item.favorite);
			}
		}, [item.favorite]);

		const handleChange = async (event) => {
			item.favorite = event.target.checked;

			try {
				const response = await customAxios.post(`/api/feedback/favorite`, {
					id: item.id,
					status: event.target.checked,
				});
				if (response) {
					showNotification("Favorite feedback was successfully changed.", "success");
				}
			} catch (err) {
				showNotification("There was a problem with favorite feedback change.", "error");
			}
		};

		return <Checkbox checked={favorite} onChange={(e) => handleChange(e)} />;
	};

	return {
		columns: [
			{ Header: "name", accessor: "name", align: "left" },
			{ Header: "phone", accessor: "phone", align: "center" },
			{ Header: "email", accessor: "email", align: "center" },
			{ Header: "message", accessor: "message", width: "*", align: "center" },
			{ Header: "favorite", accessor: "favorite", align: "center" },
			{ Header: "actions", accessor: "actions", align: "center" },
		],

		rows:
			feedbacks && feedbacks.length > 0
				? feedbacks.map((item) => ({
						name: <FeedbackName name={item.name} />,
						phone: (
							<MDTypography display="block" variant="button" fontWeight="medium">
								{item.phone}
							</MDTypography>
						),
						email: (
							<MDTypography display="block" variant="button" fontWeight="medium">
								{item.email}
							</MDTypography>
						),
						message: (
							<MDTypography
								style={{
									display: "block",
									width: "200px",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								}}
								display="block"
								variant="button"
								fontWeight="medium">
								{item.message}
							</MDTypography>
						),
						favorite: <Favorite item={item}></Favorite>,
						actions: (
							<MDBox lineHeight={1} sx={{ display: "flex", gap: "8px" }} textAlign="left">
								<MDButton
									iconOnly={true}
									variant="contained"
									size="medium"
									color="secondary"
									fontWeight="medium"
									onClick={(e) => handleConfirmationOpen(item)}>
									<Icon fontSize="medium">delete</Icon>
								</MDButton>
								<MDButton
									iconOnly={true}
									variant="contained"
									size="medium"
									color="info"
									fontWeight="medium"
									onClick={(e) => handleView(item)}>
									<Icon fontSize="medium">visibility</Icon>
								</MDButton>
								<ResponsiveDialog
									open={confirmationOpen}
									setOpen={setConfirmationOpen}
									disableClose={true}
									component={
										<ConfirmationComponent
											message={
												"Are you certain you wish to remove this feedback? Please remember, this action cannot be undone."
											}
											handleConfirm={() => handleFeedbackDelete(item)}
											handleClose={() => setConfirmationOpen(false)}
										/>
									}
								/>
								<ResponsiveDialog
									width={"300px"}
									open={openView}
									setOpen={setOpenView}
									component={<FeedbackView currentFeedback={feedback} />}></ResponsiveDialog>
							</MDBox>
						),
				  }))
				: [],
	};
}
