import React from "react";
import MDBox from "components/MDBox";
import MDProperty from "components/MDProperty";

const FeedbackView = (props) => {
	const { currentFeedback } = props;

	return (
		<MDBox sx={{ width: "100%", borderRadius: "10px" }}>
			{currentFeedback && (
				<MDBox pt={4} pb={3} px={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<MDBox component="form" role="form" sx={{ width: "100%" }}>
						<MDBox mb={2} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
							<MDBox>
								<MDProperty label={"Name"} value={currentFeedback.name} />
                                <MDProperty label={"Email"} value={currentFeedback.email} />
                                <MDProperty label={"Phone"} value={currentFeedback.phone} />
                                <MDProperty label={"Favorite"} value={currentFeedback.favorite} />
							</MDBox>
						</MDBox>
						<MDBox mb={2} sx={{ width: "100%" }}>
							<MDProperty label={"Details"} value={currentFeedback.message} />
						</MDBox>
					</MDBox>
				</MDBox>
			)}
		</MDBox>
	);
};

export default FeedbackView;
