import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BlogForm from "../blogForm";
import { useContext, useEffect, useState } from "react";
import { Icon } from "@mui/material";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import customAxios from "utils/axios";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import BlogView from "../blogView";
import DataTable from "examples/Tables/DataTable";
import brandWhite from "assets/images/logo-ct.png";

export default function BlogDataTable(props) {
	const showNotification = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);
	const [openView, setOpenView] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [blog, setBlog] = useState("");
	const [blogss, setBlogs] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);

	useEffect(() => {
		setBlogs(props.blogs);
	}, [props.blogs]);

	const handleView = (item) => {
		setBlog(item);
		setOpenView(true);
	};

	const handleClickOpen = (item) => {
		setBlogs(blogss);
		setBlog(item);
		setOpen(true);
	};

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	const handleConfirmationOpen = (item) => {
		setBlog(item);
		setConfirmationOpen(true);
	};

	const handleBlogDelete = async (item) => {
		try {
			const response = await customAxios.delete(`/api/news/${item.id}`);
			if (response) {
				setBlogs(blogss.filter((x) => x.id !== item.id));
				showNotification("The post was successfully removed.", "success");
				setConfirmationOpen(false);
			}
		} catch (err) {
			showNotification("There was a problem removing the post. Please try again.", "error");
		}
	};

	const TitleDetailsRow = ({ title, id }) => {
		const [isLoading, setIsLoading] = useState(true);

		useEffect(() => {
			const img = new Image();
			img.src = `/api/image/blogs/t/webp/${id}.webp`;
			img.onload = () => setIsLoading(false);
		}, [id]);

		return (
			<MDBox display="flex" alignItems="center" lineHeight={1}>
				{isLoading ? (
					<img
						style={{
							width: "100px",
							height: "auto",
							maxHeight: "100px",
							minHeight: "100px",
							objectFit: "contain",
						}}
						src={brandWhite}
						alt="Placeholder"></img>
				) : (
					<img
						style={{
							width: "100px",
							height: "auto",
							maxHeight: "100px",
							minHeight: "100px",
							objectFit: "contain",
						}}
						src={`/api/image/blogs/t/webp/${id}.webp`}
						alt={title}></img>
				)}
				<MDBox ml={2} lineHeight={1}>
					<MDTypography display="block" variant="button" fontWeight="medium">
						{title}
					</MDTypography>
				</MDBox>
			</MDBox>
		);
	};

	return (
		<>
			<DataTable
				table={{
					columns: [
						{ Header: "title", accessor: "title", width: "*", align: "left" },
						{ Header: "tags", accessor: "tags", align: "center" },
						{ Header: "actions", accessor: "actions", align: "center" },
					],
					rows:
						blogss && blogss.length > 0
							? blogss.map((item) => ({
									title: <TitleDetailsRow title={item.title} id={item.id} />,
									tags: <MDTypography variant="caption">{item.tags}</MDTypography>,
									actions: (
										<MDBox lineHeight={1} textAlign="left" sx={{ display: "flex", gap: "8px" }}>
											<MDButton
												iconOnly={true}
												onClick={(e) => handleConfirmationOpen(item)}
												variant="contained"
												size="medium"
												color="secondary"
												fontWeight="medium">
												<Icon>delete</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="primary"
												fontWeight="medium"
												onClick={(e) => handleClickOpen(item)}>
												<Icon>edit</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="info"
												fontWeight="medium"
												onClick={(e) => handleView(item)}>
												<Icon fontSize="medium">visibility</Icon>
											</MDButton>
										</MDBox>
									),
							  }))
							: [],
				}}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>

			<ResponsiveDialog
				aria-labelledby="customized-dialog-title"
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<BlogForm
						blog={blog}
						setBlog={setBlog}
						blogs={blogss}
						setBlogs={setBlogs}
						open={open}
						setOpen={setOpen}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				disableClose={true}
				component={
					<ConfirmationComponent
						message={
							"Are you certain you wish to remove this post? Please remember, this action cannot be undone."
						}
						handleConfirm={() => handleBlogDelete(blog)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
			<ResponsiveDialog
				width={"300px"}
				open={openView}
				setOpen={setOpenView}
				component={<BlogView currentBlog={blog} />}></ResponsiveDialog>
		</>
	);
}
