import axios from "axios";

const customAxios = axios.create();

customAxios.interceptors.request.use(function (request) {
	const token = localStorage.getItem("jwt");
	if (token) {
		request.headers["Authorization"] = `${token}`;
	}
	return request;
});

customAxios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status === 401) {
			localStorage.removeItem("jwt");
			window.location = "/authentication/sign-in";
		}
		return Promise.reject(error);
	}
);

export default customAxios;
