import React from "react";
import MDTypography from "components/MDTypography";

export default function ProductIngredientsDataTable(data) {
	return {
		columns: [
			{ Header: "name", accessor: "name", align: "left" },
			{ Header: "qty", accessor: "quantity", align: "center" },
			{ Header: "kcal", accessor: "kcal", align: "center" },
			{ Header: "details", accessor: "details", align: "left" },
			{ Header: "extra price", accessor: "extra_price", align: "left" },
			{ Header: "extra", accessor: "extra", align: "left" },
		],

		rows: data.map((item) => ({
			name: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.name}
				</MDTypography>
			),
			quantity: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.ProductIngredient.qty}
				</MDTypography>
			),
			kcal: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.ProductIngredient.kcal}
				</MDTypography>
			),
			details: (
				<MDTypography
					display="block"
					style={{
						display: "block",
						width: "150px",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden",
					}}
					variant="caption"
					color="text"
					fontWeight="medium">
					{item.ProductIngredient.details}
				</MDTypography>
			),
			extra: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.ProductIngredient.extra ? "YES" : "NO"}
				</MDTypography>
			),
			extra_price: (
				<MDTypography display="block" variant="caption" color="text" fontWeight="medium">
					{item.ProductIngredient.extra_price}
				</MDTypography>
			),
		})),
	};
}
