import customAxios from "utils/axios";

export async function getAllIngredients() {
	try {
		const response = await customAxios.get("/api/ingredient?sortBy=name&direction=ASC");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function addIngredient(data) {
	const response = await customAxios.post(`/api/ingredient`, {
		name: data.name,
		unit: data.unit,
	});
	return response.data;
}

export async function editIngredient(data) {
	const response = await customAxios.put(`/api/ingredient`, data);
	return response.data;
}

export async function deleteIngredient(id) {
	const response = await customAxios.delete(`/api/ingredient/${id}`);
	return response.data;
}
