import React, { useState } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { addFeedback } from "service/feedbackService";
import { Checkbox } from "@mui/material";

const AddFeedbackForm = (props) => {
	const paperStyle = { padding: "0 15px 40px 15px", width: 350 };
	const btnStyle = { marginTop: 15, marginLeft: "25%" };
	const [newFeedbackName, setFeedbackName] = useState("");
	const [newFeedbackMessage, setFeedbackMessage] = useState("");
	const [newFeedbackPhone, setFeedbackPhone] = useState("");
	const [newFeedbackEmail, setFeedbackEmail] = useState("");
	const [newFeedbackFavorite, setFeedbackFavorite] = useState("");

	const addNewFeedback = async () => {
		const feedback = {
			name: newFeedbackName,
			email: newFeedbackEmail,
			message: newFeedbackMessage,
			phone: newFeedbackPhone,
			favorite: newFeedbackFavorite,
		};
		async function addNewCategory() {
			const result = await addFeedback(feedback);
			if (result !== undefined) {
				const newCategories = JSON.parse(JSON.stringify(props.categories));
				newCategories.push(result);
				props.setCategories(newCategories);
			}
		}

		props.setOpen(false);
		addNewCategory();
	};

	return (
		<>
			<MDTypography variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
				Adauga feedback
			</MDTypography>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Name"
							onChange={(event) => setFeedbackName(event.target.value)}
							fullWidth
						/>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Phone"
							onChange={(event) => setFeedbackPhone(event.target.value)}
							fullWidth
						/>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Email"
							onChange={(event) => setFeedbackEmail(event.target.value)}
							fullWidth
						/>
					</MDBox>
					<MDBox mb={2}>
						<Checkbox onChange={(event) => setFeedbackFavorite(event.target.value)} />
						<MDTypography variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
							Favorite
						</MDTypography>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Message"
							onChange={(event) => setFeedbackMessage(event.target.value)}
							fullWidth
						/>
					</MDBox>
					<MDBox mt={2}>
						<MDButton
							type="button"
							style={btnStyle}
							variant="contained"
							color="secondary"
							onClick={addNewFeedback}>
							Adauga feedback
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</>
	);
};

export default AddFeedbackForm;
