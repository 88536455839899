import customAxios from "utils/axios";

export async function getAllOrders() {
	try {
		const response = await customAxios.get("/api/order?products=true");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function addOrder(data) {
	const response = await customAxios.post(`/api/order`, {
		orderType: data.orderType,
		orderNumber: data.orderNumber,
		totalPrice: data.totalPrice,
		products: data.products,
	});
	return response.data;
}

export async function editOrder(data, id) {
	const response = await customAxios.put(`/api/order/${id}`, { body: data });
	return response.data;
}

export async function deleteOrder(id) {
	const response = await customAxios.delete(`/api/order/${id}`);
	return response.data;
}
