import customAxios from "utils/axios";

export async function getAllCategories() {
	try {
		const response = await customAxios.get("/api/category");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function addCategory(data) {
	const formData = new FormData();
	formData.append("name", data.name);
	formData.append("details", data.details);
	formData.append("image", data.image);
	const response = await customAxios.post(`/api/category`, formData, {
		headers: { "content-type": "application/x-www-form-urlencoded" },
	});
	return response.data;
}

export async function editCategory(data) {
	const formData = new FormData();
	formData.append("id", data.id);
	formData.append("name", data.name);
	formData.append("details", data.details);
	formData.append("image", data.image);

	const response = await customAxios.put(`/api/category`, formData, {
		headers: { "content-type": "application/x-www-form-urlencoded" },
	});
	return response.data;
}
