import React from "react";
import MDBox from "components/MDBox";
import OrderProductsDataTable from "./data/orderProductsDataTable";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";

const OrderProductsView = (props) => {
	const { order } = props;
	const { columns, rows } = OrderProductsDataTable(order.products ? order.products : []);

	return (
		<MDBox sx={{ width: "100%", borderRadius: "10px" }}>
			{order && (
				<MDBox pt={4} pb={3} px={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<MDBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<MDTypography>Order Nr.{order.identifier}</MDTypography>
					</MDBox>
					<MDBox component="form" role="form" sx={{ width: "100%" }}>
						{rows && rows.length > 0 && (
							<MDBox pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						)}
					</MDBox>
					<MDBox sx={{ marginTop: "10%", marginLeft: "70%" }}>
						<MDTypography>Total price: {order.totalPrice}</MDTypography>
					</MDBox>
				</MDBox>
			)}
		</MDBox>
	);
};

export default OrderProductsView;
